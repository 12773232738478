import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { groupBy, random } from 'lodash-es';
import { useMemo, useRef, useState } from 'preact/hooks';
import { isFreeUser, Role } from 'zl-shared';

import { sortHabitats } from '@/helpers/habitatsSorter';
import { useOnClickOutside } from '@/hooks';
import { useSelector } from '@/redux/helper';

import Card from './Card';
import HabitatIcon from './HabitatIcon';

interface LiveChannelsBarProps {
  skipHabitatId?: string;
}

const LiveChannelsBar = ({ skipHabitatId }: LiveChannelsBarProps) => {
  const allHabitats = useSelector((state) => state.allHabitats);
  const randomOrderRef = useRef(new Map<string, number>());
  const [expand, setExpand] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const subscription = useSelector((state) => state.user.subscription);
  const favoriteHabitats = useSelector((state) => state.user.favoriteHabitats);

  const freeUser = useSelector((state) =>
    isFreeUser(state.user.role as Role, { active: state.user.subscription.active ?? false }),
  );

  const [freeHabitats, otherHabitats] = useMemo(() => {
    const favorites = new Set(favoriteHabitats ?? []);
    const freeHabitats = new Set(subscription.freeHabitats ?? []);
    const grouped = groupBy(
      allHabitats
        .filter(({ online, _id }) => online && _id !== skipHabitatId)
        .map((item) => {
          const isFavorited = favorites.has(item._id);
          const isFree = freeUser && freeHabitats.has(item._id);
          let order = randomOrderRef.current.get(item._id);
          if (order == null) {
            order = random(0, Number.MAX_SAFE_INTEGER - 1);
            randomOrderRef.current.set(item._id, order);
          }
          return { ...item, isFree, isFavorited, _randomOrder: order };
        }),
      ({ isFree }) => (isFree ? 'free' : 'other'),
    );

    const sorting = [
      (a: (typeof grouped)[string][number], b: (typeof grouped)[string][number]) => Number(b.liveTalk) - Number(a.liveTalk),
      (a: (typeof grouped)[string][number], b: (typeof grouped)[string][number]) => b._randomOrder - a._randomOrder,
    ];

    return [sortHabitats(grouped.free ?? [], null, sorting), sortHabitats(grouped.other ?? [], null, sorting)];
  }, [allHabitats, favoriteHabitats, freeUser, skipHabitatId, subscription.freeHabitats]);

  useOnClickOutside(ref, () => setExpand(false));

  return (
    <div ref={ref} className="absolute inset-0 shadow-md">
      <div className="relative z-[1] flex h-full flex-col">
        <button
          type="button"
          className="preflight preflight-button flex w-full items-center justify-center gap-1 p-2 text-xs font-bold text-grey-3"
          onClick={() => setExpand(true)}
        >
          <span>Live</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
        <div data-tour-id="liveChannelsBarHabitats" className="customScrollBar light grow space-y-2 pb-4">
          <ul
            hidden={freeHabitats.length === 0}
            data-tour-id="liveChannelsBarFreeHabitats"
            className="preflight preflight-ul flex flex-col gap-2 empty:hidden"
            data-testid="other-habitat-list-collapsed"
          >
            {freeHabitats.map((habitat) => (
              <li key={habitat._id}>
                <HabitatIcon habitat={habitat} />
              </li>
            ))}
          </ul>
          <ul hidden={otherHabitats.length === 0} className="preflight preflight-ul flex flex-col gap-2 empty:hidden">
            {otherHabitats.map((habitat) => (
              <li key={habitat._id}>
                <HabitatIcon habitat={habitat} />
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div
        aria-hidden={!expand}
        className="absolute inset-y-0 left-0 z-[1] flex translate-x-0 flex-col bg-white shadow-1 shadow-black/15 transition-transform aria-hidden:-translate-x-full aria-hidden:shadow-none"
      >
        <div className="flex items-center justify-between p-2">
          <div className="flex items-center gap-1">
            <h4 className="preflight preflight-h4 text-xs font-bold">Live</h4>
            <a href="/habitats" className="preflight preflight-a flex items-center gap-1 text-xs font-medium text-grey-3">
              <span>See all</span>
              <FontAwesomeIcon icon={faChevronRight} />
            </a>
          </div>
          <button
            type="button"
            className="preflight preflight-button flex min-w-4 items-center justify-end gap-1 text-xs font-medium text-grey-3"
            onClick={() => setExpand(false)}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            <span className="sr-only">Collapse</span>
          </button>
        </div>
        <div className="customScrollBar light grow pb-4">
          <ul className="preflight preflight-ul flex flex-col" data-testid="other-habitat-list-expanded">
            {freeHabitats.map(
              ({
                _id,
                animal,
                profileImage,
                isFree,
                slug: habitatSlug,
                isFavorited,
                zoo: { slug: zooSlug, name: zooName },
                liveTalk,
              }) => (
                <li key={_id}>
                  <Card
                    isFree={isFree}
                    image={profileImage}
                    animal={animal}
                    habitatId={_id}
                    habitatSlug={habitatSlug}
                    zoo={zooName}
                    zooSlug={zooSlug}
                    liveTalk={liveTalk}
                    isFavorited={isFavorited}
                  />
                </li>
              ),
            )}
            {otherHabitats.map(
              ({
                _id,
                animal,
                profileImage,
                isFree,
                slug: habitatSlug,
                isFavorited,
                zoo: { slug: zooSlug, name: zooName },
                liveTalk,
              }) => (
                <li key={_id}>
                  <Card
                    isFree={isFree}
                    image={profileImage}
                    animal={animal}
                    habitatId={_id}
                    habitatSlug={habitatSlug}
                    zoo={zooName}
                    zooSlug={zooSlug}
                    liveTalk={liveTalk}
                    isFavorited={isFavorited}
                  />
                </li>
              ),
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LiveChannelsBar;
