import { useEffect, useRef, useState } from 'preact/hooks';

import HighlightsList from '@/components/HighlightsList';
import { useHighlightsList } from '@/components/HighlightsList/hooks';
import { useIsMobileLayout } from '@/components/LayoutDetector/hooks';
import NotificationPermissionModal from '@/components/NotificationPermissionModal';
import { useQueryParams } from '@/hooks/route';
import useOnMount from '@/hooks/useOnMount';
import { Video, VideoType } from '@/queries/media/types';
import MobileMap from '@/routes/map/Mobile';
import { trackMP } from '@/shared/mp';

import HomeVideoSection, { MiniPlayerContentMode } from './HomeVideoSection';
import { useHabitatForStream, useIsAllHabitatsOffline } from './hooks';
import HomeHighlightVideoInfoBar from './InfoBar/HomeHighlightVideoInfoBar';
import HomeLiveStreamInfoBar from './InfoBar/HomeLiveStreamInfoBar';

const HomeContent = () => {
  const isMobile = useIsMobileLayout();
  const ref = useRef<HTMLDivElement>(null);
  const [preferMuted, setPreferMuted] = useState<boolean | undefined>();
  const [miniPlayerContentMode, setMiniPlayerContentMode] = useState<MiniPlayerContentMode>('highlight');

  const queryParams = useQueryParams();
  const newLogicTest = queryParams.get('test') === 'true';

  const isAllHabitatsOffline = useIsAllHabitatsOffline();
  const habitatForStream = useHabitatForStream(newLogicTest);

  const [highlightIndex, setHighlightIndex] = useState<number | undefined>();

  const {
    listRef,
    highlightType,
    data,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    fetchMoreIfNeeded,
    onHighlightClick: defaultOnHighlightClick,
    onChangeHighlightType: defaultOnChangeHighlightType,
  } = useHighlightsList();

  const currentHighlight = highlightIndex != null ? data?.list[highlightIndex] : undefined;

  const onNextHighlight = () => {
    if (!data || highlightIndex == null) return;
    const nextIndex = (highlightIndex + 1) % data.list.length;
    fetchMoreIfNeeded(nextIndex);
    setHighlightIndex(nextIndex);
    if (isMobile && data?.list[nextIndex]) {
      listRef.current
        ?.querySelector(`[data-id="${data.list[nextIndex]._id}"]`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const onHighlightClick = (highlight: Video, index: number) => {
    defaultOnHighlightClick(highlight, index);
    if (!data) return;
    setPreferMuted((prev) => prev ?? false);
    setHighlightIndex(index);
    setMiniPlayerContentMode('live-stream');
    if (!isMobile) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    trackMP('home-highlight-content-clicked', { indexInList: index, highlight });
  };

  const onSwitchContentMode = () => {
    setMiniPlayerContentMode((prev) => (prev === 'highlight' ? 'live-stream' : 'highlight'));
    trackMP('home-switched-miniplayer-mode', { miniPlayerCurrentContentMode: miniPlayerContentMode });
  };

  const onChangeHighlightType = (type: VideoType) => {
    defaultOnChangeHighlightType(type);
    if (type !== highlightType) {
      // set highlight index to 0 when filter changes or keep it as undefined if no playing highlight
      setHighlightIndex((prev) => (prev == null ? prev : 0));
    }
  };

  const onLoadMore = () => {
    fetchNextPage();
    trackMP('home-fetch-more-clicked', {
      miniPlayerCurrentContentMode: miniPlayerContentMode,
      habitatId: habitatForStream?._id,
      habitatName: habitatForStream?.title,
      zooName: habitatForStream?.zoo.name,
    });
  };

  const onCloseMiniPlayer = () => {
    trackMP('home-mini-player-closed', { currentHighlight });
    setHighlightIndex(undefined);
  };

  // set highlight to first video if all habitats are offline
  useEffect(() => {
    if (data?.list?.length && isAllHabitatsOffline) {
      setPreferMuted(true);
      setHighlightIndex((prev) => prev ?? 0);
      setMiniPlayerContentMode('live-stream');
    }
  }, [data?.list?.length, isAllHabitatsOffline]);

  useOnMount(() => {
    trackMP('home-visit', {
      currentHabitatId: habitatForStream?._id,
      currentHabitatInfo: habitatForStream,
      areAllHabitatsOffline: isAllHabitatsOffline,
    });
  });

  return (
    <div ref={ref} className="mobile:flex mobile:h-full mobile:flex-col mobile:overflow-hidden mobile:bg-white">
      <div className="top-0 z-10 mobile:shrink-0">
        <HomeVideoSection
          highlight={currentHighlight}
          habitat={habitatForStream}
          miniPlayerContentMode={miniPlayerContentMode}
          onNextHighlight={onNextHighlight}
          onSwitchContentMode={onSwitchContentMode}
          onCloseMiniPlayer={onCloseMiniPlayer}
          preferMuted={preferMuted}
          onMuteChange={setPreferMuted}
        />
        {miniPlayerContentMode === 'highlight'
          ? habitatForStream && <HomeLiveStreamInfoBar habitat={habitatForStream} />
          : currentHighlight && <HomeHighlightVideoInfoBar highlight={currentHighlight} onNextHighlight={onNextHighlight} />}
      </div>
      <div className="relative mobile:grow mobile:overflow-y-auto mobile:bg-secondary-green desktop:px-4 desktop:pb-3">
        {isMobile ? (
          <>
            <MobileMap />
            <NotificationPermissionModal />
          </>
        ) : (
          <>
            <HighlightsList.Header onTypeChange={onChangeHighlightType} className="sticky top-0 z-[1]" />
            <HighlightsList.Content
              key={highlightType}
              type={highlightType}
              highlights={data?.list}
              currentHighlightIndex={highlightIndex}
              onHighlightClick={onHighlightClick}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              onLoadMore={onLoadMore}
              listRef={listRef}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default HomeContent;
