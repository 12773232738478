import { createSelector } from '@reduxjs/toolkit';
import { useEffect, useMemo, useRef } from 'preact/hooks';

import { AppState, useSelector } from '@/redux/helper';
import { createSortedHabitatsSelector } from '@/redux/selectors/habitats';

const selectIsAllHabitatsOffline = createSelector(
  (state: AppState) => state.allHabitats,
  (habitats) => habitats.length > 0 && habitats.every(({ isStreamOn }) => !isStreamOn),
);

export const useHabitatForStream = (newLogicTest: boolean) => {
  const preferredHabitatIdRef = useRef<string>();
  const selectHabitatForStream = useMemo(
    () =>
      createSelector(
        createSortedHabitatsSelector(
          newLogicTest
            ? ['online', 'lastMediaCapture', 'index', 'currentViewers', 'title']
            : ['online', 'index', 'currentViewers', 'title'],
        ),

        (_: AppState, habitatId: string | undefined) => habitatId,
        (sortedHabitats, habitatId) => {
          const habitat = habitatId ? sortedHabitats.find((habitat) => habitat._id === habitatId) : undefined;
          if (habitat && habitat.isStreamOn) {
            return habitat;
          }
          return sortedHabitats.find(({ isStreamOn }) => isStreamOn);
        },
      ),
    [],
  );

  const habitat = useSelector((state) => selectHabitatForStream(state, preferredHabitatIdRef.current));

  useEffect(() => {
    preferredHabitatIdRef.current = habitat?._id;
  }, [habitat?._id]);

  return habitat;
};

export const useIsAllHabitatsOffline = () => useSelector(selectIsAllHabitatsOffline);
